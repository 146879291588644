import styled from 'styled-components'

export const SelectInputWrapper = styled.div`
  width: 100%;

  label {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;

    color: #a3b8c2;
    font-size: 1.2rem;
    font-weight: normal;
    text-transform: uppercase;

    span {
      &.asterisk {
        color: #ff3131;
        margin-right: 0.5rem;
      }
    }
  }

  .error-message {
    display: block;
    text-align: left;
    border: 1px solid transparent;

    height: auto;
    min-height: 2rem;
    margin-bottom: 0.5rem;

    color: #ff3131;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
  }

  &.oval-outline {
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 1.2rem;
      margin-left: 2.4rem;

      span {
        &.asterisk {
          color: #a3b8c2;
          margin-right: 0;
        }
      }
    }

    .error-message {
      margin-left: 2.4rem;
      color: white;
    }
  }
`
