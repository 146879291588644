import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths, useMyPermissionsQry } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { AccountsOverview } from '../../account'
import { TransactionsSection } from '../elements'
import { SpendingLimitBarUser } from '../../../components'
import { StaffDashboardNavigation, StaffDashboardWrapper } from './DashboardStaff.styled'

import SendIcon from '../../../images/icons/send.svg?react'

export const DashboardStaff = () => {
  const history = useHistory()
  const { hasWritePermission } = useMyPermissionsQry()

  return (
    <StaffDashboardWrapper>
      <StaffDashboardNavigation>
        <SpendingLimitBarUser />

        {hasWritePermission() && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={() => history.push(paths.banking.send())}
            data-test='send-money-button'
          />
        )}
      </StaffDashboardNavigation>

      <AccountsOverview />
      <TransactionsSection />
    </StaffDashboardWrapper>
  )
}
