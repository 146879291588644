// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const GraphPaneWrapper = styled.div`
  min-height: 30rem;
  margin-bottom: 4rem;

  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;

  .graph {
    width: 100%;
    height: 30rem;
  }

  @media (min-width: 1024px) {
    grid-gap: 3rem;
    grid-template-columns: 1fr 26rem;
  }
`

export const ExchangePaneWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 7rem;

  @media (min-width: 640px) {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
  }

  @media (min-width: 1024px) {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
`

export const ExchangeButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 26rem;
  margin: 0 auto;

  color: black;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.9rem;

  .left {
    display: inline-flex;
    align-items: center;

    .rate {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      padding-left: 15px;

      .price {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.7rem;
      }
    }
  }

  .change {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-left: 1rem;

    &.positive {
      color: #34a518;
    }
    &.negative {
      color: #ff3131;
    }
  }
`
