import { useState, useEffect, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { ErrorBoundary } from 'react-error-boundary'
import { withTranslation } from 'react-i18next'
import { CookiesProvider, useCookies } from 'react-cookie'

import {
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQuery,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'

import { GlobalContextProvider, OtpContext, isValidObject } from 'mmfintech-commons'

import { Error404 } from './views/auth/Error404'
import { Preloader } from 'mmfintech-portal-commons'
import { CookieConsent } from './views/cookieConsent'
import { ContentWrapper, CustomToaster, Header, ModalDialog, Otp, PrivateRoute, PublicRoute } from './components'

import theme from './theme'
import settings from './settings'
import { getRedirectByUserStatus, nonProtectedRoutes, protectedRoutes } from './routes'

import ErrorIcon from './images/icons/error.svg?react'

const AppInner = () => {
  useAppInitializerQry(settings.languages)
  const { isLoading: isFetchingAuth } = useAuthQuery(null)

  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const [cookieConsent, setCookieConsent] = useState(null)

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  useEffect(() => {
    if (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId')) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <Otp />
      })
    } else {
      modalVisible && modalHide()
    }
    // eslint-disable-next-line
  }, [queryChallenge])

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const globalContext = { modalHide, modalShow }

  if (isFetchingAuth) return <Preloader />

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <ContentWrapper>
              <Header />

              <Switch>
                {nonProtectedRoutes.map(({ path, component }, index) => (
                  <PublicRoute key={`public-route-${index}`} path={path} exact component={component} />
                ))}
                {protectedRoutes.map(({ path, component, redirect }, index) => (
                  <PrivateRoute
                    key={`private-route=${index}`}
                    path={path}
                    exact
                    component={component}
                    redirect={redirect}
                    invalidSessionRedirect={getRedirectByUserStatus(userStatus)}
                  />
                ))}
                <Route path='*' component={Error404} />
              </Switch>

              {isValidObject(cookieConsent) ? null : <CookieConsent setCookie={setCookie} />}
              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
            </ContentWrapper>
            <CustomToaster />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>An unexpected error occurred</p>
        <TryAgainButton type='button' onClick={resetErrorBoundary}>
          Go back to the home page
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          return logError({ level: 'ERROR', componentStack, message: err.toString() })
        }}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: white;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
