import parse from 'html-react-parser'
import domPurify from 'dompurify'

import { paths } from 'mmfintech-backend-api'
import { transformMerchantActivity, useActivitiesQry } from 'mmfintech-backend-api'

import { ActivityWrap } from './ActivitiesSection.styled'

import RightArrowIcon from '../../../../images/icons/arrow-right-white-small.svg?react'

export const ActivityInfoLine = ({ activity }) => {
  const { handleActivityClick } = useActivitiesQry({
    securityPath: paths.security()
  })

  const data = transformMerchantActivity(activity)
  const fixTime = i => (i < 10 ? '0' + i : i)

  const dt = new Date(activity?.time)
  const h = fixTime(dt.getHours())
  const m = fixTime(dt.getMinutes())
  const s = fixTime(dt.getSeconds())

  return (
    <ActivityWrap className='activity-section' onClick={() => handleActivityClick(data)}>
      <span className='left-side'>
        <span className='time'>
          {h}:{m}:{s}
        </span>
        <span className='description'>{parse(domPurify.sanitize(activity?.text))}</span>
      </span>
      <span className='arrow'>
        <RightArrowIcon />
      </span>
    </ActivityWrap>
  )
}
