import styled from 'styled-components'

export const CookieConsentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1400px) 1fr;

  position: fixed;
  left: 0;
  bottom: 0;

  z-index: 20;
  width: 100%;
  background-color: black;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.1);

  .consent-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    grid-column: 2 / 2;
  }

  .top-part {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;

    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;

    .top-content {
      display: flex;
      align-items: center;
      color: white;

      flex: 2;
      height: 100%;
      padding: 0 10px;

      img,
      svg {
        min-width: 6rem;
        height: 6rem;
        margin-right: 2rem;
      }
    }

    .top-buttons {
      display: flex;
      align-items: center;
      flex-direction: column;

      flex: 1;
      width: 100%;
      height: 100%;
      padding: 0 10px;

      .button {
        width: 96%;

        &.btn-manage {
          background: transparent;
          border: 1px solid white;
          color: white;
          margin: 1rem 0 1rem 0;
        }
        &.btn-accept {
          background-color: white;
          color: black;

          @media (min-width: 1000px) {
            margin-left: 2.5rem;
          }
        }

        @media (min-width: 1000px) {
          width: 20rem;
        }
      }

      @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        padding-left: 4.5rem;
      }
    }

    @media (min-width: 1000px) {
      display: flex;
      flex-direction: row;

      height: 12rem;
    }
  }

  .bottom-part {
    &.hidden {
      display: none;
    }
  }
`
