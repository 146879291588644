import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { JumpingButton } from './JumpingButton'
import {
  ActionsWrapper,
  ButtonsWrapper,
  ModalContainer,
  WelcomeModalContent,
  WelcomeModalWrapper
} from './WelcomeModal.styled'

import SendIcon from '../../../../images/icons/welcome-send.svg?react'
import DepositIcon from '../../../../images/icons/welcome-deposit.svg?react'
import ExchangeIcon from '../../../../images/icons/welcome-exchange.svg?react'

export const WelcomeModal = () => {
  const { modalHide } = useContext(GlobalContext)

  const history = useHistory()

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    modalHide()
  }

  const handleSendMoneyClick = () => {
    history.push(paths.banking.send())
    modalHide()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    modalHide()
  }

  return (
    <ModalContainer>
      <WelcomeModalWrapper data-test='welcome-modal'>
        <WelcomeModalContent>
          <h1 className='title'>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Bank of Central!')}</h1>

          <div className='welcome-text'>
            {tr('FRONTEND.WELCOME.MODAL.COMPLETE.TEXT', 'Complete your account to get access to all features!')}
          </div>

          <ActionsWrapper>
            <JumpingButton
              icon={<DepositIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              onClick={handleDepositClick}
              data-test='button-deposit'
            />
            <JumpingButton
              icon={<ExchangeIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              onClick={handleExchangeClick}
              data-test='exchange-button'
            />
            <JumpingButton
              icon={<SendIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
              onClick={handleSendMoneyClick}
              data-test='send-money-button'
            />
          </ActionsWrapper>

          <ButtonsWrapper>
            <Button
              color='primary'
              text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
              onClick={modalHide}
            />
          </ButtonsWrapper>
        </WelcomeModalContent>
      </WelcomeModalWrapper>
    </ModalContainer>
  )
}
