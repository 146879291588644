import { formatMoney, tr } from 'mmfintech-commons'
import { selectCurrentUserId, useAppSelector, useCurrencies, useGetUserExpenseLimitsQuery } from 'mmfintech-backend-api'

import { ProgressBarWithInfoWrapper, ProgressBarInfoWrapper } from './SpendingLimitBar.styled'

export const SpendingLimitBarUser = () => {
  const merchantUserId = useAppSelector(selectCurrentUserId)

  const { getCurrencyPrecision } = useCurrencies()

  const {
    data: expenseLimits
    // error: expenseLimitsError,
    // isFetching: expenseLimitsFetching
  } = useGetUserExpenseLimitsQuery({ merchantUserId })

  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = expenseLimits || {}

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  if (!expenseLimits) {
    return null
  }

  return (
    <div>
      <ProgressBarInfoWrapper>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money Spent:')}
          </span>
          {formatMoney(spentMonthlyVolume, currency, getCurrencyPrecision(currency))}
        </div>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONTHLY_LIMIT', 'Monthly Limit:')}{' '}
          </span>
          {monthlyVolume ? (
            formatMoney(monthlyVolume, currency, getCurrencyPrecision(currency))
          ) : (
            <span className='infinity'>∞</span>
          )}
        </div>
      </ProgressBarInfoWrapper>
      <ProgressBarWithInfoWrapper percentage={progressPercentage} monthlyVolume={monthlyVolume}>
        <div className='progress-bar'>
          <div>{monthlyVolume ? Math.round(progressPercentage) : 0}%</div>
        </div>
      </ProgressBarWithInfoWrapper>
    </div>
  )
}
