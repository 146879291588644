import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useAppDispatch } from '../../hooks'
import { tr, useDropDownMenu } from 'mmfintech-commons'
import { api, paths, useLazyLogoutQuery } from 'mmfintech-backend-api'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'

export const UserDropDownMenu = ({ UserMenuItems }) => {
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const [logout] = useLazyLogoutQuery()

  const handleLogout = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hideDropDown()
    history.push(paths.logout())
    await logout(null).unwrap()
    dispatch(api.util.resetApiState())
  }

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className='menu-icon' />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          <UserMenuItems onClick={hideDropDown} className='menu-item selector' />
          <div className='menu-footer'>
            <Link to='#' className='menu-item logout' onClick={handleLogout}>
              {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            </Link>
          </div>
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
