import styled from 'styled-components'

export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(96, 96, 96, 0.5);

  outline: 0;
  overflow: hidden;
  z-index: 5000;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal-footer {
    .button {
      max-width: 15rem;
      &.primary {
        margin-left: 2rem;
      }
    }
  }

  .title {
    color: #000000;
  }
`

export const ModalContainer = styled.div`
  margin: 0;
  padding: 0;

  height: auto;
  max-height: 95vh;
  overflow-y: auto;

  max-width: 95vw;
  width: 60rem;
  &.size-extra-large {
    width: 120rem;
  }
  &.size-large {
    width: 90rem;
  }
  &.size-small {
    width: 30rem;
  }
  &.size-auto {
    width: auto;
  }

  &.horizontal {
    display: flex;
    justify-content: center;
  }
  &.centered {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    box-shadow: none;
    //overflow-y: hidden;

    .scrollable {
      height: 100%;
      overflow-y: auto;
    }
  }

  color: black;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: normal;

  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  &.overflow-none {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  &.transparent {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
`
