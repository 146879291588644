import { SumSubBanner } from '../../onboarding'
import { AccountSection, GraphSection, TransactionsSection } from '../elements'

export const DashboardAdmin = () => (
  <>
    <AccountSection />
    <SumSubBanner className='dashboard' />
    <GraphSection />
    <TransactionsSection />
  </>
)
