import { useContext, useEffect } from 'react'

import { useAppDispatch } from '../../hooks'
import { GlobalContext, tr } from 'mmfintech-commons'
import {
  resetEmailVerification,
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector
} from 'mmfintech-backend-api'

import { DashboardAdmin } from './DashboardAdmin'
import { DashboardStaff } from './DashboardStaff'
import { ActivitiesSection, WelcomeModal } from './elements'
import { DashboardLeft, DashboardRight, DashboardTitle, DashboardWrapper } from './Dashboard.styled'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const customerRole = useAppSelector(selectCurrentUserRole)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })

      dispatch(resetEmailVerification())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  return (
    <DashboardWrapper>
      <DashboardLeft>
        <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>
        {customerRole === CustomerRoleEnum.STAFF ? <DashboardStaff /> : <DashboardAdmin />}
      </DashboardLeft>

      <DashboardRight>
        <ActivitiesSection />
      </DashboardRight>
    </DashboardWrapper>
  )
}

export default Dashboard
