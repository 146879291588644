import styled from 'styled-components'

export const ApplicationFeePreviewWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .title {
    display: block;
    color: black;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: left;
  }
  .amount-wrapper {
    background: #f4f6f6;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    width: 100%;
  }
  .amount-label {
    color: #85a0ad;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .amount {
    font-weight: 600;
    font-size: 2rem;
    margin-top: 0.5rem;
  }

  button:first-of-type {
    margin-bottom: 1rem;
  }
`
