import styled from 'styled-components'

export const ActivitiesTitle = styled.div`
  color: #85a0ad;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  text-align: center;
  text-transform: capitalize;
`

export const DateSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  width: 100%;
  margin: 3rem 0;
  padding: 0 3rem;

  @media (max-width: 1400px) {
    padding: 0;
    margin: 1rem 0;
  }

  .grid-line {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid black;
  }

  .date-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    margin: 0 1.6rem;

    color: black;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.7rem;
    text-transform: capitalize;

    .day {
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 4.4rem;
    }
  }
`

export const ActivityWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  width: 100%;
  padding: 1.6rem 3rem;

  color: black;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  @media (max-width: 1400px) {
    padding: 1.6rem 2rem;
  }

  .left-side {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .time {
    color: #a3b8c2;
    margin-right: 2rem;
  }

  .arrow {
    margin-left: 1rem;
    margin-right: -1rem;
    width: 2rem;

    img,
    svg {
      display: none;
    }
  }

  &:hover {
    color: white;
    background-color: black;

    .time {
      color: white;
    }
    transition: all 500ms;

    .arrow {
      img,
      svg {
        display: inline-block;
      }
    }
  }
`
