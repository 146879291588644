import { tr } from 'mmfintech-commons'
import { useOnboarding } from '../../../hooks'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { Centered, LargeButton, SumSubBannerWrapper, TextBody } from './SumSubBanner.styled'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import WarningTriangleIcon from '../../../images/icons/warning-yellow.svg?react'

export const SumSubBanner = ({ className }) => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus } = merchant || {}

  const { requestIban, shouldRequestIban, payAccountSetupFee, shouldPayAccountSetupFee, startOrContinueOnboarding } =
    useOnboarding()

  const Banner = ({ children }) => <SumSubBannerWrapper className={className}>{children}</SumSubBannerWrapper>

  if (accountType === MerchantAccountTypeEnum.PROSPECT) {
    if (
      onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
      onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
      onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
    ) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.RESUME_ALERT_TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
              'Verify to get access to all features and increased payment limits.'
            )}
          </TextBody>
          <Centered className='ml-2'>
            {onboardingStatus === OnboardingStatusEnum.NOT_STARTED ? (
              <LargeButton
                data-test='start-onboarding'
                onClick={() => startOrContinueOnboarding()}
                text={tr('FRONTEND.ONBOARDING.VERIFY', 'Verify')}
              />
            ) : (
              <Button
                data-test='start-onboarding'
                onClick={() => startOrContinueOnboarding()}
                text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              />
            )}
          </Centered>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.MESSAGE',
              'Complete the final step of your account activation - Additional Documents'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <Button
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              data-test='start-onboarding'
              onClick={() => startOrContinueOnboarding()}
            />
          </Centered>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.QUESTIONNAIRE.KYB.TITLE', 'KYB Questionnaire')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.QUESTIONNAIRE.MESSAGE',
              'Complete the questionnaire in order to proceed with the account onboarding'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <Button
              data-test='start-onboarding'
              text={tr('FRONTEND.ONBOARDING.QUESTIONNAIRE.BUTTON', 'Start')}
              onClick={() => startOrContinueOnboarding()}
            />
          </Centered>
        </Banner>
      )
    }

    if (
      onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
      onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
    ) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.DASHBOARD.SUMSUB.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
            </div>
          </TextBody>
        </Banner>
      )
    }

    if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody className='centered'>
            <div className='onboarding-resume-header'>
              {tr(
                'FRONTEND.DASHBOARD.SUMSUB.REJECTED_MESSAGE',
                'Please contact Customer Service to get support on your account verification.'
              )}
            </div>
          </TextBody>
        </Banner>
      )
    }

    if (shouldPayAccountSetupFee()) {
      return (
        <Banner>
          <Centered className='mr-2'>
            <WarningTriangleIcon />
          </Centered>
          <TextBody>
            <div className='onboarding-resume-header'>
              {tr('FRONTEND.ONBOARDING.SETUP_FEE.TITLE', 'Complete your account')}
            </div>
            {tr(
              'FRONTEND.ONBOARDING.SETUP_FEE.MESSAGE',
              'You need to pay your application fee to enable your primary account.'
            )}
          </TextBody>
          <Centered className='ml-2'>
            <LargeButton
              data-test='setup-fee-button'
              onClick={payAccountSetupFee}
              text={tr('FRONTEND.ONBOARDING.SETUP_FEE.PAY_NOW_BUTTON', 'Pay Now')}
            />
          </Centered>
        </Banner>
      )
    }
  }

  if (shouldRequestIban()) {
    return (
      <Banner>
        <Centered className='mr-2'>
          <WarningTriangleIcon />
        </Centered>
        <TextBody>
          <div className='onboarding-resume-header'>
            {tr('FRONTEND.ONBOARDING.REQUEST_IBAN.TITLE', 'Complete your account')}
          </div>
          {tr('FRONTEND.ONBOARDING.REQUEST_IBAN.MESSAGE', 'You can get your own dedicated EUR IBAN.')}
        </TextBody>
        <Centered className='ml-2'>
          <LargeButton
            data-test='request-iban'
            onClick={requestIban}
            text={tr('FRONTEND.ONBOARDING.REQUEST_IBAN.REQUEST_BUTTON', 'Request now')}
          />
        </Centered>
      </Banner>
    )
  }

  return null
}
