import { useContext } from 'react'

import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, GlobalContext, tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { ApplicationFeePreviewWrapper } from './ApplicationFeePreviewModal.styled'

interface ApplicationFeeInfoProps {
  amount: number
  currency: string
  onSubmit: () => void
}

export const ApplicationFeePreviewModal = ({ amount, currency, onSubmit }: ApplicationFeeInfoProps) => {
  const { modalHide } = useContext(GlobalContext)
  const { getCurrencyPrecision } = useCurrencies()

  return (
    <ApplicationFeePreviewWrapper>
      <div className='title'>{tr('FRONTEND.APPLICATION_FEE.INFO.TITLE', 'Application Fee Details')}</div>
      <div className='amount-wrapper'>
        <div className='amount-label'>{tr('FRONTEND.APPLICATION_FEE.INFO.AMOUNT.LABEL', 'Amount')}</div>
        <div className='amount'>{formatMoney(amount, currency, getCurrencyPrecision(currency))}</div>
      </div>
      <div className='buttons-container'>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.APPLICATION_FEE.INFO.PAY.BUTTON', 'Pay')}
          data-test='button-pay'
          onClick={onSubmit}
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          data-test='button-cancel'
          onClick={modalHide}
        />
      </div>
    </ApplicationFeePreviewWrapper>
  )
}
