import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalContext, isValidObject, isValidString, OtpContext, tr } from 'mmfintech-commons'
import {
  paths,
  responseDoesNotContainsChallenge,
  useApplicationFeeInitiateMutation,
  useGetMerchantQuery,
  useMerchantAccounts,
  useOnboardingBannerQry
} from 'mmfintech-backend-api'

import { SuccessMessage } from './SuccessMessage'
import { ApplicationFeeDetailsModal } from './ApplicationFeeDetailsModal'
import { ApplicationFeePreviewModal } from './ApplicationFeePreviewModal'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

const errorTimeout = 1000 * 3 // 3 seconds

export const useOnboarding = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [showError, setShowError] = useState<boolean>(true)

  const { data: merchant } = useGetMerchantQuery()
  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const { activeAccounts } = useMerchantAccounts()

  const [initiateApplicationFeePayment, { error: applicationFeeInitiateError }] = useApplicationFeeInitiateMutation()

  const history = useHistory()

  const findEuroAccount = () => activeAccounts?.find(account => account.currencyCode === 'EUR')

  const startSumSubOnboarding = () => {
    if (
      !isValidString(entityType) ||
      (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED)
    ) {
      history.push(paths.onboarding.selectType())
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: startSumSubOnboarding
  })

  const handlePayAccountSetupFeeSuccess = () => {
    modalShow({
      options: {
        size: 'auto',
        transparent: true,
        closeOnClickOutside: false,
        closeOnEscape: false
      },
      content: (
        <SuccessMessage message={tr('FRONTEND.PAYMENT.SUCCESS', 'Your Application Fee has been paid successfully')} />
      )
    })
  }

  const handlePayAccountSetupFee = async () => {
    setOtpOnSuccess(() => handlePayAccountSetupFeeSuccess)

    try {
      const euroAccount = findEuroAccount()
      const response = await initiateApplicationFeePayment({ accountId: euroAccount.id })
      if (responseDoesNotContainsChallenge(response)) {
        handlePayAccountSetupFeeSuccess()
      }
    } catch {}
  }

  const payAccountSetupFee = () => {
    modalHide()
    setShowError(true)

    const euroAccount = findEuroAccount()
    const { availableBalance } = euroAccount || {}

    const { amount, currency } = accountSetupFee || {}

    if (availableBalance >= amount) {
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <ApplicationFeePreviewModal amount={amount} currency={currency} onSubmit={handlePayAccountSetupFee} />
      })
    } else {
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true
        },
        content: <ApplicationFeeDetailsModal amount={amount} currency={currency} />
      })
    }
  }

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible
  const requestIban = () => history.push(paths.ibanIssuing())

  useEffect(() => {
    if (applicationFeeInitiateError) {
      setTimeout(() => {
        setShowError(false)
      }, errorTimeout)
    }
  }, [applicationFeeInitiateError])

  return {
    showError,
    requestIban,
    shouldRequestIban,
    payAccountSetupFee,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}
