import { useExchangePairsQry } from 'mmfintech-backend-api'

import { GraphChart } from './GraphChart'
import { ExchangeButton } from './ExchangeButton'
import { ExchangePaneWrapper, GraphPaneWrapper } from './GraphSection.styled'

export const GraphSection = () => {
  const { updatedFxRates } = useExchangePairsQry({ fixedRates: null })

  return (
    <GraphPaneWrapper>
      <div className='graph'>
        <GraphChart />
      </div>

      <ExchangePaneWrapper>
        {updatedFxRates
          ?.filter((_node: any, index: number) => index < 4)
          .map((node, index) => <ExchangeButton key={index} node={node} />)}
      </ExchangePaneWrapper>
    </GraphPaneWrapper>
  )
}
