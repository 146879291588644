import { useHistory } from 'react-router-dom'

import parse from 'html-react-parser'

import { tr } from 'mmfintech-commons'
import {
  paths,
  useDepositInitiateMutation,
  useDepositPreviewMutation,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { MerchantEntityTypeEnum, PaymentMethod, PaymentMethodEnum } from 'mmfintech-commons-types'

import applicationFeeImage from '../../images/app-fee-no-funds-image.png'

import BankWebServiceIcon from '../../images/icons/bank-web-service-icon.svg?react'
import PaymentNetworkIcon from '../../images/icons/payment-network-icon.svg?react'
import ReferenceIcon from '../../images/icons/reference-icon.svg?react'
import TransferDetailsIcon from '../../images/icons/transfer-details-icon.svg?react'
import SepaIcon from '../../images/icons/sepa-icon.svg?react'
import SwiftIcon from '../../images/icons/swift-icon.svg?react'
import ArrowIcon from '../../images/icons/arrow-right-small-grey.svg?react'
import { ApplicationFeeDetailsWrapper, PaymentMethodButton, PaymentStep } from './ApplicationFeeDetailsModal.styled'

const paymentSteps = [
  {
    icon: <PaymentNetworkIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_1',
      'Choose the payment network of your preference - <b>SWIFT</b> or <b>SEPA</b>'
    )
  },
  {
    icon: <TransferDetailsIcon />,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.STEP_2', 'Our bank transfer details will appear on the screen ')
  },
  {
    icon: <BankWebServiceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_3',
      'Log into your bank’s web service (or go to your bank) to transfer the funds'
    )
  },
  {
    icon: <ReferenceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_4',
      'Don’t forget to add the <b>reference</b>. It will help us process your deposit faster'
    )
  }
]

interface ApplicationFeeDetailsProps {
  amount: number
  currency: string
}

export const ApplicationFeeDetailsModal = ({ amount, currency }: ApplicationFeeDetailsProps) => {
  const history = useHistory()

  const { data: merchant } = useGetMerchantQuery()
  const { entityType } = merchant || {}

  const [previewRequest, { error: previewError, isLoading: previewLoading }] = useDepositPreviewMutation()
  const [initiateRequest, { error: initiateError, isLoading: initiateLoading }] = useDepositInitiateMutation()

  const handleSelectPaymentOption = async (paymentMethod: PaymentMethod) => {
    if (merchant && !previewLoading && !initiateLoading) {
      if (currency && entityType !== MerchantEntityTypeEnum.INDIVIDUAL) {
        try {
          const data = { amount, currency, paymentMethod }
          await previewRequest(data).unwrap()
          await initiateRequest(data).unwrap()
          history.push({
            pathname: paths.banking.depositPreview(),
            state: { paymentType: paymentMethod }
          })
        } catch {}
      }
    }
  }

  return (
    <ApplicationFeeDetailsWrapper>
      <img src={applicationFeeImage} alt='application-fee' />
      <div className='title'>
        {tr('FRONTEND.APPLICATION_FEE.DETAILS.TITLE', "Bank of Central won't take money from your bank automatically")}
      </div>
      <div className='subtitle'>
        {tr('FRONTEND.APPLICATION_FEE.DETAILS.SUBTITLE', "You'll need to do this bit yourself to pay by bank transfer")}
      </div>
      {paymentSteps.map(({ icon, text }, i) => (
        <PaymentStep key={i}>
          {icon}
          <div>{parse(text)}</div>
        </PaymentStep>
      ))}
      <ErrorDisplay
        error={
          previewError || initiateError
            ? {
                ...(previewError ? previewError : initiateError),
                error: 'The payment method is currently unavailable.',
                errorKey: 'MERCHANTS.ERROR.PAYMENT_METHOD_UNAVAILABLE'
              }
            : null
        }
      />
      <div className='buttons-container'>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.SWIFT_BANKWIRE)}>
          <div className='left'>
            <SwiftIcon />
            <span>SWIFT</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.BANKWIRE)}>
          <div className='left'>
            <SepaIcon />
            <span>SEPA</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
      </div>
    </ApplicationFeeDetailsWrapper>
  )
}
