import { useEffect, useState } from 'react'

import { isValidArray, tr } from 'mmfintech-commons'
import { useGetLastMerchantActivitiesQuery } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { ActivitiesTitle } from './ActivitiesSection.styled'
import { ActivityDateLine } from './ActivityDateLine'
import { ActivityInfoLine } from './ActivityInfoLine'

export const ActivitiesSection = () => {
  const [activities, setActivities] = useState(null)

  const { data, error, isFetching } = useGetLastMerchantActivitiesQuery()

  useEffect(() => {
    if (isValidArray(data)) {
      const list = []
      data.forEach(item => {
        const dt = new Date(item.time).toDateString()
        let innerList = list.find(v => v.date === dt)
        if (innerList == null) {
          innerList = { date: dt, list: [item] }
          list.push(innerList)
        } else {
          innerList.list.push(item)
        }
      })
      setActivities(list)
    }
  }, [data])

  return (
    <>
      <ActivitiesTitle>{tr('FRONTEND.DASHBOARD.ACTIVITY_TITLE', 'Activity')}</ActivitiesTitle>

      {isFetching ? null : error ? (
        <ErrorDisplay error={error} />
      ) : isValidArray(activities) ? (
        activities.map((item: any, index: number) => (
          <span className='line-wrap' key={`dt-${index}`}>
            <ActivityDateLine datum={item.date} />
            {item.list.map((activity: any, index2: number) => (
              <ActivityInfoLine key={`act-${index}-${index2}`} activity={activity} />
            ))}
          </span>
        ))
      ) : null}
    </>
  )
}
