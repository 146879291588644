import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
`

export const WelcomeModalWrapper = styled.div`
  width: 100%;
  max-width: 64rem;
  padding: 4rem;

  border-radius: 1rem;
  background-color: white;

  @media (max-width: 480px) {
    margin: auto;
  }
`

export const WelcomeModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  text-align: center;

  .title {
    font-size: 2.4rem;
    font-weight: 400;
  }

  .welcome-text {
    padding: 1rem 0;
    font-size: 1.4rem;

    &.italic {
      font-style: italic;
    }
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  margin: 2rem 0;

  .round-square-container {
    margin: 0 0.5rem;
  }

  @media (max-width: 480px) {
    flex-flow: column nowrap;
    div:nth-of-type(2) {
      margin: 1rem 0;
    }
  }
`

export const ButtonsWrapper = styled.div`
  margin-top: 3rem;
`
