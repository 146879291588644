import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { formatMoney, GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import {
  hintKeysEnum,
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useGetAggregatedBalanceQuery,
  useHintsQry,
  useMyPermissionsQry
} from 'mmfintech-backend-api'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { Callout, UnlockedFeatureModal } from '../../../../components'
import { AccountSectionWrapper, BalanceContainer } from './AccountSection.styled'

import DepositIcon from '../../../../images/icons/deposit.svg?react'
import ExchangeIcon from '../../../../images/icons/exchange.svg?react'
import RedDotIcon from '../../../../images/icons/new-dot-icon.svg?react'
import SendIcon from '../../../../images/icons/send.svg?react'

export const AccountSection = () => {
  const { modalShow } = useContext(GlobalContext)

  const customerRole = useAppSelector(selectCurrentUserRole)

  const { balance, error: balanceError } = useGetAggregatedBalanceQuery(
    {
      dateFrom: '',
      dateTo: ''
    },
    {
      selectFromResult: ({ data, error }) => ({
        balance: isValidArray(data) ? data[data.length - 1] : null,
        error
      })
    }
  )

  const { hints, isHintSeen, saveHint } = useHintsQry()

  const history = useHistory()
  const { hasWritePermission } = useMyPermissionsQry()

  return (
    <>
      <AccountSectionWrapper>
        <BalanceContainer>
          {!!balance && (
            <>
              <div className='label'>{tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}</div>
              <div data-test='balance'>{formatMoney(balance.amount, balance.currencyCode)}</div>
            </>
          )}
        </BalanceContainer>

        <div className='buttons-container'>
          {isOwnerOrAdministrator(customerRole) && (
            <Button
              type='button'
              color='secondary'
              icon={<DepositIcon />}
              text={
                <Callout hintKey={hintKeysEnum.deposit} content={<RedDotIcon />} isOpen={true}>
                  {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                </Callout>
              }
              onClick={async () => {
                history.push(paths.banking.deposit())
                if (hints && !isHintSeen(hintKeysEnum.deposit)) {
                  void saveHint(hintKeysEnum.deposit)
                  modalShow({
                    content: <UnlockedFeatureModal type='deposit' />
                  })
                }
              }}
              data-test='deposit-button'
              data-for='button-tooltip'
            />
          )}

          {hasWritePermission() && (
            <Button
              type='button'
              color='secondary'
              icon={<SendIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
              onClick={() => history.push(paths.banking.send())}
              data-test='send-money-button'
            />
          )}

          {isOwnerOrAdministrator(customerRole) && (
            <Button
              type='button'
              color='secondary'
              icon={<ExchangeIcon />}
              text={
                <Callout hintKey={hintKeysEnum.exchange} content={<RedDotIcon />} isOpen={true}>
                  {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
                </Callout>
              }
              onClick={async () => {
                history.push(paths.banking.exchange())
                if (hints && !isHintSeen(hintKeysEnum.exchange)) {
                  void saveHint(hintKeysEnum.exchange)
                  modalShow({
                    content: <UnlockedFeatureModal type='exchange' />
                  })
                }
              }}
              data-test='exchange-button'
              data-for='button-tooltip'
            />
          )}
        </div>
      </AccountSectionWrapper>

      <ErrorDisplay error={balanceError} />
    </>
  )
}
