import styled from 'styled-components'

import Background from '../../../images/404-tkb.png'

export const MessageWrapper = styled.div`
  grid-row: 1 / 3;
  grid-column: left-column / right-column;
  width: 100%;
  padding: 10rem 0 0;
  margin: 0;
`

export const BackgroundImage = styled.div`
  //prettier-ignore
  background: black url("${Background}") center no-repeat;
  background-size: cover;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 100;
`

export const MessageContainer = styled.div`
  z-index: 101;
  width: 100%;
  height: 100%;
  min-height: 30rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

export const MessageContent = styled.div`
  z-index: 102;
  margin: 5rem 0;
  text-align: center;

  .error-code {
    color: #ff3131;
    font-size: 14.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: 14.4rem;
  }

  .title {
    color: white;
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: 7.7rem;
    text-align: center;

    margin-bottom: 6rem;
  }

  .button {
    max-width: 35rem;
    margin: 0 2rem;

    color: black;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.2rem;

    background-color: white;
    border: 1px solid white;
    border-radius: 3px;

    &:hover {
      background-color: white;
    }
  }
`
