import styled from 'styled-components'

import HamburgerMenuIcon from '../../images/icons/hamburger-menu.svg'

export const DropDownMenuWrapper = styled.div`
  position: relative;
  &.hamburger {
    height: 6rem;
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
  }
`

export const DropDownMenuContainer = styled.div`
  position: absolute;
  left: -15rem;
  width: 20rem;
  z-index: 1001;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  padding: 1.5rem;

  .menu-item {
    border-radius: 4px;
    height: 4.5rem;

    color: black;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;

    justify-content: flex-start;

    :hover {
      color: #ff3131;
      background: rgba(255, 49, 49, 0.1);
    }

    &.selector {
      padding: 0 1rem;
    }
  }

  .menu-footer {
    border-top: 1px solid #eeeeee;
    padding-top: 1.5rem;
    margin-top: 1.5rem;

    text-align: center;

    .logout {
      width: 100%;
      max-width: 12rem;
      display: inline-flex;
      justify-content: center;

      color: white;
      background-color: black;
      padding: 2rem 1rem;
      border-radius: 4px;
    }
  }
`

export const DropDownMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 1.6rem 2.5rem;

  cursor: pointer;

  .menu-icon {
    width: 2.2rem;
    height: 2.2rem;
    //prettier-ignore
    background: url("${HamburgerMenuIcon}") center center no-repeat;
  }

  &.arrow-down {
    transition: transform 150ms ease;
    &.active {
      transform: rotate(-180deg);
    }
  }
`
