import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { SuccessContainer, SuccessSubtitle } from './SuccessMessage.styled'

import SuccessImage from '../../images/icons/success.svg'

export const SuccessMessage = ({ message }) => {
  const { modalHide } = useContext(GlobalContext)

  return (
    <SuccessContainer className='centered' data-test='application-fee-paid-success'>
      <img src={SuccessImage} alt='' />
      <SuccessSubtitle className='title'>{message}</SuccessSubtitle>
      <Button
        type='button'
        color='primary'
        text={tr('FRONTEND.BUTTONS.OK', 'OK')}
        onClick={modalHide}
        data-test='button-ok'
      />
    </SuccessContainer>
  )
}
