import { ReactNode } from 'react'

import { JumpingButtonStyled, JumpingButtonWrapper } from './JumpingButton.styled'

interface JumpingButtonProps {
  icon?: ReactNode
  label?: ReactNode
  onClick: () => void
  [key: string]: any
}

export const JumpingButton = (props: JumpingButtonProps) => {
  const { icon, label, onClick, ...rest } = props

  return (
    <JumpingButtonWrapper>
      <JumpingButtonStyled className='round-square-container button' onClick={onClick} {...rest}>
        {icon}
        {label}
      </JumpingButtonStyled>
    </JumpingButtonWrapper>
  )
}
