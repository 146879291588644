import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'

import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './AccountsOverview.styled'

export const AccountsOverview = () => {
  const { permissions, permissionsFetching } = useMyPermissionsQry()

  const { activeAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || permissionsFetching) {
    return <Preloader />
  }

  const accounts = activeAccounts?.map((account: any) => {
    const permission = permissions?.find((perm: any) => account.id === perm.accountId)
    return { ...account, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts.map((account: any, index: number) => (
        <AccountOverviewRow account={account} key={index} />
      ))}
    </AccountOverviewListWrapper>
  )
}
