// noinspection CssUnusedSymbol

import styled from 'styled-components'

import { CommonPageTitle } from '../../components'

export const DashboardWrapper = styled.div`
  grid-area: main-content;
  padding: 0 2.5rem;

  height: calc(100vh - 9rem);
  display: flex;
  position: relative;
  flex-flow: row nowrap;

  .no-data {
    color: #a3b8c2;
  }

  @media (max-width: 1400px) {
    height: auto;
    flex-flow: column nowrap;
  }
`

export const DashboardLeft = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  padding-right: 0;
  margin-bottom: 3rem;

  @media (min-width: 1400px) {
    overflow-y: auto;
    padding-top: 5rem;
    padding-right: 4rem;
    max-height: 100%;
    margin-bottom: 0;
  }
`

export const DashboardTitle = styled(CommonPageTitle)`
  margin-bottom: 1.5rem;
`

export const DashboardRight = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  span.line-wrap {
    width: 100%;
  }

  @media (min-width: 1400px) {
    border-left: 1px solid rgba(163, 184, 194, 0.3);
    border-right: 1px solid rgba(163, 184, 194, 0.3);

    align-items: center;
    max-width: 40rem;
    max-height: 100%;
    padding-top: 5rem;
    overflow-y: auto;
  }
`
