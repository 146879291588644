import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

import { CurrencyIcon } from '../../../components'
import { AccountOverviewRowWrapper } from './AccountsOverview.styled'

import { AccountBalanceOut } from 'mmfintech-commons-types'

interface AccountOverviewRowProps {
  account: AccountBalanceOut & { permission?: string }
}

export const AccountOverviewRow = ({ account }: AccountOverviewRowProps) => {
  const { currencyCode, balance, name, permission } = account

  const { getCurrencyPrecision } = useCurrencies()

  return (
    <AccountOverviewRowWrapper>
      <div className='cell'>
        <CurrencyIcon currency={currencyCode} size='3.4rem' className='mr-1 currency-icon' />
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.OVERVIEW.CURRENCY', 'Currency')}</span>
        <span>{currencyCode}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.OVERVIEW.ACCOUNT_NAME', 'Account name')}</span>
        <span className='subtitle'>{name}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.OVERVIEW.PERMISSIONS', 'Permissions')}</span>
        <span className='subtitle'>
          {permission === 'WRITER'
            ? tr('FRONTEND.ACCOUNTS.OVERVIEW.ACCESS_FULL', 'Full')
            : tr('FRONTEND.ACCOUNTS.OVERVIEW.ACCESS_VIEW_ONLY', 'View only')}
        </span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('FRONTEND.ACCOUNTS.OVERVIEW..BALANCE', 'Balance')}</span>
        <span className='subtitle'>
          {formatMoney(balance || 0, currencyCode || '', getCurrencyPrecision(currencyCode))}
        </span>
      </div>
    </AccountOverviewRowWrapper>
  )
}
