import React from 'react'

import Login from './views/auth/Login'
import Dashboard from './views/dashboard/Dashboard'

import { paths } from 'mmfintech-backend-api'
import { LoginStatusEnum } from 'mmfintech-commons-types'

const LazyExchange = React.lazy(() => import('./views/banking/Exchange'))
const LazySendMoney = React.lazy(() => import('./views/banking/SendMoney'))
const LazyTransactions = React.lazy(() => import('./views/transactions'))
const LazyTransactionStatus = React.lazy(() => import('./views/transactions/TransactionStatus'))
const LazyVerificationSumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))

// prettier-ignore
export const nonProtectedRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.signUp(), component: React.lazy(() => import('./views/auth/Signup')) },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordMessage')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.handleEmailVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordMessage')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },

  { path: paths.banking.finalStatus.fail(':method'), params: { isSuccess: false }, component: LazyTransactionStatus },
  { path: paths.banking.finalStatus.success(':method'), params: { isSuccess: true }, component: LazyTransactionStatus },
]

// prettier-ignore
export const protectedRoutes = [
  { path: paths.root(), exact: true, redirect: paths.dashboard() },
  // { path: paths.logout(), component: Logout },

  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: React.lazy(() => import('./views/settings/Profile')) },
  { path: paths.security(), component: React.lazy(() => import('./views/settings/Security')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },

  { path: paths.banking.depositPreview(), exact: true, component: React.lazy(() => import('./views/banking/ApplicationFeeDepositPreview')) },
  { path: paths.banking.deposit(), component: React.lazy(() => import('./views/banking/Deposit')) },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), component: LazyExchange },
  { path: paths.banking.exchange(), component: LazyExchange },
  { path: paths.banking.sendBankTransfer(), exact: true, component: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendCrypto(), exact: true, component: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendTransfer(), exact: true, component: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: React.lazy(() => import('./views/banking/WalletTransfer')) },
  { path: paths.banking.sendTo(':recipientId'), component: LazySendMoney },
  { path: paths.banking.send(), component: LazySendMoney },
  { path: paths.banking.expenses(), component: React.lazy(() => import('./views/requests/Requests')) },

  { path: paths.banking.accounts.list(), component: React.lazy(() => import('./views/account/Accounts')) },
  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: LazyTransactions },
  { path: paths.banking.transactions.list(), component: LazyTransactions },

  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients/Recipients')) },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer')) },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SelectOnboardingType')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: LazyVerificationSumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: LazyVerificationSumSub }
]

export const getRedirectByUserStatus = (status: string): string => {
  switch (status) {
    case LoginStatusEnum.NOT_VERIFIED:
      return paths.checkEmail()
    case LoginStatusEnum.FORGOT_PASSWORD:
      return paths.changePasswordEmail()
    case LoginStatusEnum.RESET_PASSWORD:
      return paths.handlePasswordResetInvited()
    case LoginStatusEnum.PASSWORD_RESET:
      return paths.passwordReset()
    default:
      return paths.login()
  }
}
