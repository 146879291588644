import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'
import { Button } from 'mmfintech-portal-commons'

import { BackgroundImage, MessageContainer, MessageContent, MessageWrapper } from './Error404.styled'

export const Error404 = () => {
  const history = useHistory()

  return (
    <MessageWrapper>
      <BackgroundImage />

      <MessageContainer>
        <MessageContent>
          <div className='error-code'>404</div>
          <div className='title'>{tr('FRONTEND.ERROR404.TITLE', 'Page not found.')}</div>

          <Button
            type='button'
            onClick={() => history.push(paths.dashboard())}
            text={tr('FRONTEND.ERROR404.BACK_BUTTON', 'Back to dashboard')}
            data-test='back-button'
          />
        </MessageContent>
      </MessageContainer>
    </MessageWrapper>
  )
}
